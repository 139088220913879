import { Injectable } from '@angular/core';
import { S3Client, PutObjectCommand, PutObjectCommandInput, ObjectCannedACL } from '@aws-sdk/client-s3';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class S3Service {

  private s3: S3Client;

  constructor() {
    this.s3 = new S3Client({
      region: environment.aws.region,
      credentials: {
        accessKeyId: environment.aws.accessKeyId,
        secretAccessKey: environment.aws.secretAccessKey,
      },
    });
  }

  async uploadFile(file: File, bucketName: string, fileName: string, acl: ObjectCannedACL = 'public-read'): Promise<string> {
    const params: PutObjectCommandInput = {
      Bucket: bucketName,
      Key: fileName,
      Body: file,
      ACL: acl 
    };

    try {
      const command = new PutObjectCommand(params);
      await this.s3.send(command);
      const s3Url = `https://${bucketName}.s3.${environment.aws.region}.amazonaws.com/${fileName}`;
      return s3Url;
    } catch (err) {
      console.error('Error uploading file to S3:', err);
      throw err;
    }
  }
}
