import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SamikshaBackButtonService {
  private clientName: string|null =null;
  constructor(private router: Router
    ) {
    const storedClientName = localStorage.getItem('clientName');
    if (storedClientName) {
      this.clientName = storedClientName;
    }
   }

  setClientName(client: string): void {
    this.clientName = client;
  }

  handleBack(): boolean {
    const currentUrl: string = this.router.url;
    if (this.clientName && this.clientName.includes('samiksha')) {
      if (currentUrl.includes('/client/samiksha?client=samiksha')) {
        return true;
      }
      window.history.back();
      return false;
    }
    // if client is not samiksha don't do anything
    return false;
  }
}
