import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BadgesRoutingModule } from './badges-routing.module';
import { BadgesComponent } from './badges.component';
import { SharedModule } from '../shared/shared.module';
import { MatCardModule } from '@angular/material/card';


@NgModule({
  declarations: [
    BadgesComponent
  ],
  imports: [
    CommonModule,
    BadgesRoutingModule,
    SharedModule,
    MatCardModule
  ]
})
export class BadgesModule { }
