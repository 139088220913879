<div class="error-dialogue">
<div class="modal-header">
  <h5 class="modal-title" *ngIf="data.message!=='The requested resource was not found'">Oops</h5>
  <h5 *ngIf="data.message==='The requested resource was not found'" class="modal-title"><p class="new-to-CodeMitra">Dont have an account ?<span (click)="signup()" class="sign-up-text"> Sign up</span></p>
  </h5>

  <button type="button" class="close" (click)="onDismiss()">
    <span>&times;</span>
  </button>
</div>
<div class="modal-body">
  {{data.message==='The requested resource was not found'? "This phone number is not registered Please check your entry or register a new account.":data.message }}
</div>
</div>
