<!-- <mat-form-field appearance="outline"> -->
  <input #digit class="form-control digit-input" [formControl]="digitControl" (keyup)="onDigitInput($event, digit.value)" step="1"
  (focus)="onInputFocus()" [tabindex]="tabindex" type="number" min="0" max="9" maxlength="1"/>


<!-- </mat-form-field> -->

<!-- <div class="col-4">
    <div class="form-outline">
        <input #digit class="form-control" [formControl]="digitControl" (keyup)="onDigitInput($event, digit.value)" 
          step="1" (focus)="onInputFocus()" [tabindex]="tabindex" type="number" />
        
      </div>
    </div> -->