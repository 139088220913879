import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'pijam-auth-layout-modal',
  templateUrl: './auth-layout-modal.component.html',
  styleUrls: ['./auth-layout-modal.component.scss']
})
export class AuthLayoutModalComponent implements OnInit {
  @Input()
  title!: string;

  constructor() { }

  ngOnInit(): void {
  }

}
