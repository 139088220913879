import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastrModule } from 'ngx-toastr';
import { NgxPijamApiClientModule } from 'ngx-pijam-api-client';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BadgesModule } from './badges/badges.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from './shared/shared.module';
import { MetaTagsModule } from './meta/meta-tags/meta-tags.module';
import { DeleteAccountModule } from './delete-account/delete-account.module';
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    DeleteAccountModule,
    NgxPijamApiClientModule.forRoot({ ...environment }),
    BrowserAnimationsModule,
    BadgesModule,
    NgbModule,
    SharedModule,
    MetaTagsModule,
    ToastrModule.forRoot(),
  ],
  providers: [{ provide: 'Window', useValue: window }],
  bootstrap: [AppComponent],
})
export class AppModule {}
