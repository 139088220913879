import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToolbarComponent } from './toolbar.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SidebarLayoutComponent } from './sidebar-layout.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { AuthLayoutComponent } from './auth-layout.component';
import { MatCardModule } from '@angular/material/card';
import { SpinnerComponent } from './spinner.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DialogComponent } from './dialog/dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PointsBadgeComponent } from './points-badge.component';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { MatGridListModule } from '@angular/material/grid-list';
import { HeaderComponent } from './header/header.component';
import { ModalModule } from '../modal/modal.module';
import { AuthModule } from '../auth/auth.module';
import { FooterComponent } from './footer.component';
import { PinInputComponent } from './pin-input/pin-input.component';
import { HourOfCodeComponent } from '../courses/hour-of-code/hour-of-code.component';
import { CourseTileComponent } from './course-tile/course-tile.component';
import { AutoLandscapeViewComponent } from './auto-landscape-view/auto-landscape-view.component';
import { VideoRecorderComponent } from './video-recorder/video-recorder.component';
import { AudioRecorderComponent } from './audio-recorder/audio-recorder.component';
import { ColorfulButtonComponent } from './colorful-button/colorful-button.component';
// import { AuthComponent } from '../auth/auth.component';
@NgModule({
  declarations: [
    ToolbarComponent,
    SidebarLayoutComponent,
    AuthLayoutComponent,
    SpinnerComponent,
    DialogComponent,
    PointsBadgeComponent,
    HeaderComponent,
    FooterComponent,
    PinInputComponent,
    HourOfCodeComponent,
    CourseTileComponent,
    AutoLandscapeViewComponent,
    VideoRecorderComponent,
    AudioRecorderComponent,
    ColorfulButtonComponent,    
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatChipsModule,
    RouterModule,
    MatGridListModule,
    ModalModule,
    // AuthModule,
    // AuthComponent
  ],
  exports: [
    ToolbarComponent,
    SidebarLayoutComponent,
    AuthLayoutComponent,
    SpinnerComponent,
    DialogComponent,
    PointsBadgeComponent,
    HeaderComponent,
    FooterComponent,
    PinInputComponent,
    HourOfCodeComponent,
    AutoLandscapeViewComponent,
    VideoRecorderComponent,
    AudioRecorderComponent,
    ColorfulButtonComponent,
  ]
})
export class SharedModule { }
