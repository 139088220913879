<mat-card>
  <div class="title" >{{ title }}</div>
  <mat-card-header>
    
    <!-- <mat-card-subtitle></mat-card-subtitle> -->
  </mat-card-header>
  <mat-card-content>
    <ng-content></ng-content>
  </mat-card-content>
  <mat-card-actions >
    <ng-content select="[actions]"></ng-content>
  </mat-card-actions>
</mat-card>
