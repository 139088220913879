import { Component, OnInit,Input } from '@angular/core';
import { PointsService } from 'ngx-pijam-api-client';
import { Observable } from 'rxjs';
@Component({
  selector: 'pijam-points-badge',
  templateUrl: './points-badge.component.html',
  styleUrls: ['./points-badge.component.scss']
})
export class PointsBadgeComponent implements OnInit {

  points$!: Observable<number>;
  @Input()
  rewardPoints!: number | null;
  constructor(private pointsService: PointsService) { }

  ngOnInit(): void {
    this.points$ = this.pointsService.points;
  }

}
