export const environment = {
  apiBaseUrl: 'https://stage-api.codemitra.org/', // https://api.codemitra.org/ //'http://dev-alb-292865525.ap-south-1.elb.amazonaws.com/',
  openLabVideoUrl:
    'https://d51e583nnuf2e.cloudfront.net/open-lab-video/video.mp4',
  production: false,
  requestOtpUrl:
    'https://api.codemitra.org/lambda/smsotp-service-dev-generate_otp', // 'http://dev-alb-292865525.ap-south-1.elb.amazonaws.com/lambda/smsotp-service-test-generate_otp',
  storeVersionUrl:
    'https://d51e583nnuf2e.cloudfront.net/version.json',
  topicActivityUrl: 'https://activity-lab-handover.s3.ap-south-1.amazonaws.com',
  lessonImageBucket:
    'https://d51e583nnuf2e.cloudfront.net/cards/',
  mixpanelProjectToken: '753d837bb2b5faf17006ed3424a222b7',
  hourOfCodeBucket:
    'https://activity-lab-handover.s3.ap-south-1.amazonaws.com/',
  frontendAssetsImage: 'https://d51e583nnuf2e.cloudfront.net/Frontend/assets/img/',

  // aws access key and region
  aws: {
    accessKeyId: 'AKIAWWYAAQAXXNE7QDST',
    secretAccessKey: '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',
    region: 'ap-south-1',
  }

};
