import { Component, OnInit, OnDestroy, EventEmitter, Input, Output } from '@angular/core';
import { S3Service } from 'src/app/services/s3.service';
import { mixpanelEvent } from '../pi.constants';
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';
import buttonData from '../../competition/competition-topics/competition-topic/competition-multimedia-input/multimedia.json'

declare var MediaRecorder: any;

type AudioRecordingState = "INITIAL" | "RECORDING" | "RECORDED" | "PLAYING" | "UPLOADING" | "UPLOADED" | "ERROR"

@Component({
  selector: 'app-audio-recorder',
  templateUrl: './audio-recorder.component.html',
  styleUrls: ['./audio-recorder.component.scss']
})
export class AudioRecorderComponent implements OnInit, OnDestroy {
  private mediaRecorder: any;
  private chunks: any[] = [];
  private audio: any;
  @Input() rendererContextData: { [key:string]: string} = {};
  state: AudioRecordingState = "INITIAL";
  error: String = "";
  audioMimeType: string = 'audio/ogg; codecs=opus';
  audioURL?: string;
  uploadUrl: string = "";
  AudioButtonData!:any ;
  languageCode!: string;

  private languageChangeSubscription!: Subscription;


  @Output() uploadUrlEmitter: EventEmitter<{
    idea_audio: string;
    type: string;
  }> = new EventEmitter<{
    idea_audio: string;
    type: string;
  }>();
  @Input() fileName: string = `AUD-${Math.random()*9999}-${Math.random()*9999}-${Math.random()*9999}.webm`
  @Input() showBackButton = false;
  @Output() onBackClicked: EventEmitter<void> = new EventEmitter<void>();
  
  constructor(private s3Service: S3Service, private languageService: LanguageService,) {}

  ngOnInit(): void {
    this.setLanguage();
    this.audio = new Audio();
    
  }

  setLanguage(): void {
    this.languageCode = this.languageService.getCurrentLanguage();
    this.updateData();
  
    this.languageChangeSubscription = this.languageService.subscribeToLanguageChanges().subscribe(language => {
      this.languageCode = language;
      this.updateData();
    });
  }

  updateData(): void {
    this.AudioButtonData = (buttonData as { [key: string]: any })[this.languageCode].RECORD_AUDIO_LABELS;
 }

  async startRecording() {
    try {
      this.chunks = [];
      this.audioURL = undefined;
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);
      
      this.mediaRecorder.ondataavailable = (event: any) => {
        this.chunks.push(event.data);
      };

      this.mediaRecorder.onstop = () => {
        this.audioMimeType = 'audio/ogg; codecs=opus';
        const blob = new Blob(this.chunks, { 'type': this.audioMimeType });
        this.audioURL = URL.createObjectURL(blob);
      };

      this.mediaRecorder.start();
      this.state = "RECORDING";
      this.error = "";
      mixpanelEvent.buttonClicked("START_RECORDING", {
        type: "AUDIO",
        ...this.rendererContextData
      })
    } catch (err: any) {
      console.error('Error accessing microphone', err);
      alert('Error accessing microphone: ' + err?.message);
      this.state = "ERROR";
      this.error = "Error in recording audio" + `, ${err.message}` ?? "";
    }
  }

  stopRecording() {
    this.mediaRecorder.stop();
    this.state = "RECORDED";
    mixpanelEvent.buttonClicked("STOP_RECORDING", {
      type: "AUDIO",
      ...this.rendererContextData
    })
  }

  playAudio() {
    this.audio.src = this.audioURL;
    this.audio.play();
    this.state = "PLAYING";

    this.audio.onended = () => {
      this.state = "RECORDED";
    };
    mixpanelEvent.buttonClicked("PLAY_PREVIEW", {
      type: "AUDIO",
      ...this.rendererContextData
    })
  }

  stopAudio() {
    this.audio.pause();
    this.audio.currentTime = 0;
    this.state = "RECORDED"
    mixpanelEvent.buttonClicked("STOP_PREVIEW", {
      type: "AUDIO",
      ...this.rendererContextData
    })
  }

  ngOnDestroy(): void {
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
    }
    if (this.audio) {
      this.audio.pause();
      this.audio.src = '';
    }

    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
  }

  submitAudio = async () =>  {
    this.state = "UPLOADING";
    mixpanelEvent.buttonClicked("SUBMIT_AND_CONTINUE", {
      type: "AUDIO",
      ...this.rendererContextData
    })
    try {
      const fileName = this.fileName + `${Math.random() * 9999}` + ".ogg";
      const blob = new Blob(this.chunks, { type: this.audioMimeType });
      const file = new File([blob], fileName, { type: this.audioMimeType });
      const bucketName: string = 'user-responses-storage'; 
      // const fileName: string = file.name + ".webm"; 
      await this.s3Service.uploadFile(file, bucketName, fileName);
      this.uploadUrl = `https://${bucketName}.s3.amazonaws.com/${fileName}`;
      this.state = "UPLOADED";
      this.continueAfterUpload()
    } catch (error: any) {
      this.error = 'Upload failed: ' + error.message;
      this.state = 'ERROR';
      mixpanelEvent.checkpoint("ERROR_ENCOUNTERED", {
        type: "AUDIO",
        message: error.message ?? "",
        ...this.rendererContextData
      })
    }
  }

  continueAfterUpload = async () => {
    this.uploadUrlEmitter.emit({
       idea_audio: this.uploadUrl,
       type: "RECORDED_AUDIO"
    });
  }

  handleBack = (event: any) => {
    event?.stopPropagation();
    this.onBackClicked.emit();
  }


}

