import { Injectable } from '@angular/core';
import { IdmService } from 'ngx-pijam-api-client'; 
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
  })
  export class AuthService {
    private isAuthSubject = new BehaviorSubject<boolean>(false);
    isAuth$ = this.isAuthSubject.asObservable();
  
    constructor(private idmService: IdmService) {
      // Initialize auth status based on the result of isLoggedInUser
      this.idmService.isLoggedInUser().then(loggedIn => {
        this.isAuthSubject.next(loggedIn);
      });
    }
  
    // Method to set the user as authenticated
    login() {
      this.isAuthSubject.next(true);
    }
  
    // Method to set the user as not authenticated
    logout() {
      this.isAuthSubject.next(false);
    }
  
    // Method to check if the user is authenticated
    isAuthenticated(): boolean {
      return this.isAuthSubject.value;
    }
  }
  