
<mat-card>
    <mat-card-header>
      <mat-card-title>Code Mitra</mat-card-title>
      <mat-card-subtitle>{{ title }}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ng-content></ng-content>
    </mat-card-content>
    <mat-card-actions align="end">
      <ng-content select="[actions]"></ng-content>
    </mat-card-actions>
  </mat-card>
  