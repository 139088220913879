<footer id="footer" class="footer">
    
    <div class="footer-top">
      <div class="container">
        <div class="row gy-4">
          <div class="col-lg-5 col-md-12 footer-info">
            <h1>About</h1>
            <p>
              Learn problem solving and computer science on your phone through fun
              and interactive courses. Become a creator by making your own games,
              animations or projects.
            </p>
          </div>
  
          <div
            class="col-lg-7 col-md-12 footer-contact text-center text-md-start"
          >
            <h1>Contact Us</h1>
            <p>
              Mantri Manor, B16, Lane Number 5, Suyojana Society,<br />
              Kavadewadi, Koregaon Park, Pune, Maharashtra 411001 <br /><br />
              <strong>Phone:</strong> +91-7972666742 / +91-9820803677<br />
              <strong>Email:</strong> contact@thepijam.org<br />
            </p>
            <div class="social-links mt-3">
              <a
                href="https://twitter.com/pijamfoundation?s=20"
                target="_blank"
                class="twitter"
                ><i class="bi bi-twitter"></i
              ></a>
              <a
                href="https://www.facebook.com/thepijammers/"
                target="_blank"
                class="facebook"
                ><i class="bi bi-facebook"></i
              ></a>
              <a
                href="https://www.instagram.com/pijamfoundation/?hl=en"
                target="_blank"
                class="instagram"
                ><i class="bi bi-instagram"></i
              ></a>
              <a
                href="https://www.linkedin.com/company/thepijam/?originalSubdomain=in"
                target="_blank"
                class="linkedin"
                ><i class="bi bi-linkedin"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <div class="container">
      <div class="copyright">
        &copy; Copyright, 2023 <strong><span>Pi Jam Foundation</span></strong
        >. All Rights Reserved
      </div>
    </div>
    <div *ngIf="version" class="version-container">
      Version {{version}}
    </div>
  </footer>

  <ng-template #loading>
    <pijam-spinner></pijam-spinner>
  </ng-template>