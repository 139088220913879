import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

// This is to test Samiksha app's back-button handling
// TODO: handle back behaviour basis on the current screen user is at and the browser history. We might need to remove it from here and paste it in separate screens
//@ts-ignore

