import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
export interface DialogData {
  message: string;
}

@Component({
  selector: 'pijam-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
  }

  onDismiss() {
    this.dialogRef.close();
  }
  signup(){
    this.closeModal()
    this.router.navigate(['/auth']); 
  }
  closeModal(){
    const tempButton:any = document.getElementById('closeModalButton');
    tempButton.click();
    this.onDismiss()
  }
}
