import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IdmService } from 'ngx-pijam-api-client';

@Injectable({
  providedIn: 'root'
})
export class NonAuthGuard implements CanActivate {
  constructor(private idmService: IdmService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.idmService.isLoggedInUser().then(loggedIn => {
        localStorage.setItem("Auth", loggedIn.toString());
        if (loggedIn) {
          return this.router.parseUrl('/home');
        } else {
          return true;
        }
      });
  }

}
