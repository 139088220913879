import { Component, OnInit } from '@angular/core';
import { IdmService } from 'ngx-pijam-api-client';
import { ThemeControllerService } from 'src/app/services/theme-controller.service';

@Component({
  selector: 'pijam-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(
    public idmService: IdmService,
    public themeControllerService: ThemeControllerService
  ) {}

  ngOnInit(): void {}
}
