import { Component, OnInit, HostListener, ElementRef, AfterViewInit} from '@angular/core';

@Component({
  selector: 'pijam-auto-landscape-view',
  templateUrl: './auto-landscape-view.component.html',
  styleUrls: ['./auto-landscape-view.component.scss']
})
export class AutoLandscapeViewComponent implements OnInit, AfterViewInit {
  innerWidth: number = 0;
  innerHeight: number = 0;

  constructor(private el: ElementRef) {
    this.updateWindowSize();
  }

  ngOnInit(): void {
    this.updateWindowSize();
  }

  ngAfterViewInit() {
    this.updateWindowSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: any) {
    this.updateWindowSize();
  }

  updateWindowSize() {
    this.innerWidth = window.innerWidth;
    this.innerHeight = window.innerHeight;

    let currentElement = this.el.nativeElement.querySelector('.AutoRotateContainer');
    if (this.innerHeight > this.innerWidth && currentElement) {
      const transform = `rotate(90deg) translate(0px, -${this.innerWidth}px)`;
      currentElement.style.width = `calc(${this.innerHeight}px)`;
      currentElement.style.height = `calc(${this.innerWidth}px)`;
      currentElement.style.transformOrigin = `0 0`;
      currentElement.style.transform = transform;
    } else if (currentElement && this.innerHeight < this.innerWidth) {
      const transform = `rotate(0deg) translate(0px, 0px)`;
      currentElement.style.width = this.innerWidth + "px";
      currentElement.style.height = this.innerHeight + "px";
      currentElement.style.transformOrigin = `0 0`;
      currentElement.style.transform = transform; 
    }
  }

}
