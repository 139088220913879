<!-- <pijam-toolbar></pijam-toolbar> -->

<pijam-header></pijam-header>

<pijam-sidebar-layout>
  <div class="container">
    <div id="badges" *ngIf="badges$ | async as badges; else noBadges">
      <h2>Badges earned</h2>
      <mat-card *ngFor="let badge of badges">
        <mat-card-header>
          <mat-card-title>{{ badge.name }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>{{ badge.timesEarned }} badges</mat-card-content>
      </mat-card>
    </div>
    <ng-template #noBadges>
      <div>
        <h2>You haven't earned any badges yet</h2>
      </div>
    </ng-template>
  </div>
</pijam-sidebar-layout>
