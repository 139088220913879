import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'pijam-colorful-button',
  templateUrl: './colorful-button.component.html',
  styleUrls: ['./colorful-button.component.scss']
})
export class ColorfulButtonComponent implements OnInit {
  @Input() icon?: "undo" | "upload_file" | "touch_app" | "music_note" | "redo" | "camera" | "mic" | "play_arrow" | "play_circle_outline" | "stop_circle_outlined" | "spellcheck" | "stop" |  "movie" | "done";
  @Input() color: "blue" | "purple" | "red" | "orange" | "green" | "grey" = "orange";
  @Input() text =  "";
  @Output() click = new EventEmitter<Event | void>()
  @Input() disabled = false;

  constructor() { }

  ngOnInit(): void {
  }

  handleClick = (event: any) => {
    event.stopPropagation();
    if (!this.disabled) {
      this.click.emit(event)
    }
  }

}
