import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { mixpanelEvent } from '../shared/pi.constants';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  private readonly storageKey = 'locale';
  private currentLanguage: string;
  private languageSubject = new Subject<string>();

  constructor() {
    this.currentLanguage = this.getLanguageFromLocalStorage() || 'en';
    this.updateLanguage(this.currentLanguage);
  }

  getCurrentLanguage(): string {
    return this.currentLanguage;
  }

  updateLanguage(language: string): void {
    this.currentLanguage = language;
    localStorage.setItem(this.storageKey, language);
    this.languageSubject.next(language); 

    mixpanelEvent.buttonClicked("LANGUAGE_CHANGE", {
      language: language,
    });
  }

  subscribeToLanguageChanges(): Subject<string> {
    return this.languageSubject;
  }

  private getLanguageFromLocalStorage(): string | null {
    return localStorage.getItem(this.storageKey);
  }
}
