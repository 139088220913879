import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { IdmService } from 'ngx-pijam-api-client';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private idm: IdmService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.idm.isLoggedInUser().then(loggedIn => {
        localStorage.setItem("Auth", loggedIn.toString());
        if (loggedIn) {
          return true;
        } else {
          return this.router.parseUrl('/landing');
        }
      });
  }
}
