import { Component, OnInit, EventEmitter, Output, ElementRef, ViewChild, OnChanges, Input, SimpleChanges, AfterViewInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { LoginModalComponent } from '../login-modal/login-modal.component';
@Component({
  selector: 'pijam-otp-digit-modal',
  templateUrl: './otp-digit-modal.component.html',
  styleUrls: ['./otp-digit-modal.component.scss']
})
export class OtpDigitModalComponent implements OnInit, OnChanges, AfterViewInit {
  @ViewChild('digit') input!: ElementRef<HTMLInputElement>;
  @ViewChild(LoginModalComponent) otpDigitModal!: LoginModalComponent;
  @Input()
  hasFocus = false;

  @Input()
  tabindex!: number;

  @Output()
  keyUp = new EventEmitter<string | null>();

  digitControl = new FormControl('', [Validators.required, Validators.pattern('[0-9]')]);

  constructor() { }

  onDigitInput(event: KeyboardEvent, digit: string) {
    if (event.keyCode === 8) {
      this.keyUp.emit(null);
    } else if (digit) {
      this.keyUp.emit(digit);
    }
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['hasFocus']) {
      if (changes['hasFocus'].currentValue && this.input) {
        this.input.nativeElement.value = '';
        this.input.nativeElement.focus();
      } else if (!changes['hasFocus'].currentValue && this.input) {
          this.input.nativeElement.blur();
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => this.hasFocus ? this.input.nativeElement.focus() : undefined);
  }

  onInputFocus() {
    this.hasFocus = true;
    if (this.input) {
      this.input.nativeElement.value = '';
    }
  }
   
    resetValues(): void {
      this.digitControl.setValue('');
    }
}
