import { Component, OnInit } from '@angular/core';
import { BadgesService, Badge } from 'ngx-pijam-api-client';
import { Observable } from 'rxjs';

@Component({
  selector: 'pijam-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})
export class BadgesComponent implements OnInit {
  badges$!: Observable<Badge[]>;

  constructor(private badgesService: BadgesService) { }

  ngOnInit(): void {
    this.badges$ = this.badgesService.getBadges();
  }

}
