<!-- <pijam-auth-layout-modal title="Login"> -->
    <!-- <div *ngIf="errorOccured && error==='Unknown User'" class="modal-title"><p class="new-to-CodeMitra">Dont have an account ?<span  class="sign-up-text"> Sign up</span></p>
</div> -->
  <form>
    <div class="input-group digit-container mt-4">
      <div class="input-group-prepend">
        <span class="input-group-text digit-type rounded" id="basic-addon3">{{ LoginType.toUpperCase() }}</span>
      </div>
      <pijam-otp-digit-modal fieldName="first" [tabindex]="0" [hasFocus]="hasFocus(0)" (keyUp)="onOtpDigitKeyUp($event, 0)"></pijam-otp-digit-modal>
      <pijam-otp-digit-modal fieldName="second" [tabindex]="1" [hasFocus]="hasFocus(1)" (keyUp)="onOtpDigitKeyUp($event, 1)"></pijam-otp-digit-modal>
      <pijam-otp-digit-modal fieldName="third" [tabindex]="2" [hasFocus]="hasFocus(2)" (keyUp)="onOtpDigitKeyUp($event, 2)"></pijam-otp-digit-modal>
      <pijam-otp-digit-modal fieldName="fourth" [tabindex]="3" [hasFocus]="hasFocus(3)" (keyUp)="onOtpDigitKeyUp($event, 3)"></pijam-otp-digit-modal>
    </div>
  </form>
    <div class="login-container">
    <button actions [disabled]="!otpCode.valid"  class="login-button" (click)="LoginType==='otp'?handleLoginWithOtp():handleLoginWithPin()" >Login</button>
  </div>
    <!-- </pijam-auth-layout-modal> -->
