 <div
  (click)="onClick()"
  class="hoc-container"
>
  <div class="col mb-4">
    <div class="card ml-4">
      <img [src]="course.image" class="card-img-top" alt="{{ course.name }}" />
      <div class="card-body">
        <div class="detail-container row">
          <div class="ml-3">
            <h2 class="card-title">{{ course.name }}</h2>
          </div>
          <div class="mr-3" >
            <img class="status-image" *ngIf="course.status" [src]="getAssetPath('lesson-Intersection.svg')" />
          </div>
        </div>
        <p class="card-text">
          {{ course.short_desc }}
        </p>
      </div>
    </div>
  </div>
</div>
