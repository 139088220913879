
<div class="VideoRecorderContainer">
    <div class="video-container">
        <video #video autoplay playsinline muted="true"></video>
    </div>
    <div class="ButtonContainer FullSpan">
        <div class="PaddedContainer" *ngIf="state !== 'recording'">
            <h4 class="TextContainer">
                {{videoButtonData?.title}}
            </h4>
        </div>
        <div class="PaddedContainer AbsoluteBottomCenter">
            <pijam-colorful-button
                *ngIf="state !== 'recording'"
                (click)="requestPermissions()"
                [text]="videoButtonData?.Start_Recording"
                icon="play_circle_outline"
                color="purple">
            </pijam-colorful-button>
            <pijam-colorful-button
                *ngIf="showBackButton && state !== 'recording'"
                (click)="handleBack($event)"
                [text]="videoButtonData?.different_method"
                icon="touch_app"
                color="grey">
            </pijam-colorful-button>
            <pijam-colorful-button
                *ngIf="state === 'recording'"
                (click)="stopRecording()"
                [text]="videoButtonData?.Stop_Recording"
                icon="stop_circle_outlined"
                color="red">
            </pijam-colorful-button>
        </div>
    </div>
    <div class="OverlappingContainer" *ngIf="state === 'recorded'" style="z-index: 100">
        <div class="video-container">
            <video #playback controls></video>
        </div>
        <div *ngIf="showPlayButton" class="OverlappingContainer OverlayBg">
            <div class="PaddedContainer">
                <pijam-colorful-button
                    (click)="playVideo()"
                    [text]="videoButtonData?.Play_Preview"
                    icon="play_circle_outline"
                    color="purple">
                </pijam-colorful-button>
            </div>
            <div class="PaddedContainer">
                <pijam-colorful-button
                    (click)="requestPermissions()"
                    [text]="videoButtonData?.Retake_Video"
                    icon="redo"
                    color="orange">
                </pijam-colorful-button>
            </div>
            <div class="PaddedContainer">
                <pijam-colorful-button
                    *ngIf="showBackButton"
                    (click)="handleBack($event)"
                    [text]="videoButtonData?.different_method"
                    icon="touch_app"
                    color="grey">
                </pijam-colorful-button>
            </div>
            <div class="PaddedContainer">
                <pijam-colorful-button
                    (click)="uploadVideo()"
                    [text]="videoButtonData?.Confirm_Video"
                    icon="done"
                    color="green">
                </pijam-colorful-button>
            </div>
        </div>
    </div>
    <div class="OverlappingContainer OverlayBg" *ngIf="state === 'uploading'">
        <h3> Uploading... </h3>
        <p>
            Please don't close the app until the upload is complete
        </p>
    </div>
    <div class="OverlappingContainer OverlayBg" *ngIf="state === 'uploaded'">
        <h3> Hurray! </h3>
        <p>Video uploaded successfully</p>
        <br />
    </div>
    <div *ngIf="state === 'error'" class="OverlappingContainer ErrorBg" style="text-align:center;">
        <p>Error: {{ error }}</p>
        <pijam-colorful-button
            (click)="requestPermissions()"
            text="Try again"
            icon="play_arrow"
            color="purple">
        </pijam-colorful-button>
    </div>  
</div>