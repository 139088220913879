<nav *ngIf="themeControllerService.getTheme()=='theme-blue' || themeControllerService.getTheme()=='theme-default'"
  class="navbar navbar-expand-lg navbar-dark shadow fixed-top blue-theme">
  <a class="navbar-brand" (click)="homeNavigation()">
    <img class="mr-3" [src]="getAssetPath('logo-in-circle.svg')" width="52px">
    <span>CODE MITRA</span>
  </a>


  <button *ngIf="client == null" class="navbar-toggler border-0" id="navbar-collapse-button" type="button"
    data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
    aria-expanded="false" aria-label="Toggle navigation">
    <p class="navbar-toggler-icon"></p>
  </button>

  <div *ngIf="client == null" class="collapse navbar-collapse" id="navbarSupportedContent"
    [ngClass]="{'show': isNavbarCollapsed}" style="justify-content: end;">
    <div class='navbar-expander'></div>
    <ul class="navbar-nav mr-auto text-success a-container" *ngIf="isAuth">
      <li class="nav-item active">
        <a class="link-success   a-link underline-animation smallscreen" (click)="closeNavbar()"
          [routerLink]="['/']">Home</a>
      </li>

    </ul>
    <div>
      <form class="form-inline my-2 my-lg-0 ">
        <form class="form-inline ">
          <div class="user-details " *ngIf="isAuth">

            <span class="user-name smallscreen" *ngIf="isAuth&&isLargeScreen">Welcome, {{ name }} !</span>
          </div>
          <div class="dropdown mr-3" *ngIf="isAuth && !hideAccountButton">
            <button class="btn dropdown-toggle dropdown-theme" type="button" id="dropdownMenuButton"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Account
            </button>
            <div class="dropdown-menu drop-down-menu" aria-labelledby="dropdownMenuButton">
              <a class="dropdown-item dropdown-item-name">{{name}}</a>
              <a class="dropdown-item" (click)="closeNavbar()" [routerLink]="['/setpin']">Change/Set Pin</a>
              <a class="dropdown-item" (click)="closeNavbar()" *ngIf="isAuth" (click)="onLogout()">Log out</a>
            </div>
          </div>

          <div class="dropdown lang-dropdown" *ngIf="isAuth">
            <button class="btn btn-outline-success language-btn dropdown-toggle" type="button" id="languageDropdown"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img class="mr-1" [src]="getAssetPath('green-theme/language-icon.svg')" />
              <img class="hide-on-small-screen" [src]="getAssetPath('green-theme/down-arrow.svg')" />

            </button>
            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="languageDropdown">
              <a class="dropdown-item" (click)="selectLanguage('en')">English</a>
              <a class="dropdown-item" (click)="selectLanguage('hi')">Hindi</a>
              <a class="dropdown-item" (click)="selectLanguage('pu')">Punjabi</a>
              <a class="dropdown-item" (click)="selectLanguage('be')">Bengali</a>
              <a class="dropdown-item" (click)="selectLanguage('or')">Oriya</a>
              <a class="dropdown-item" (click)="selectLanguage('ta')">Tamil</a>
            </div>
          </div>


          <div *ngIf="!isAuth" class="authenticate-container">
            <button type="button" id="OpenLoginModal" class="authenticate-btn  smallscreen" (click)="onSignInClick()"
              *ngIf="!isAuth" data-toggle="modal" data-target="#exampleModalCenter">Sign in</button>
          </div>
        </form>
      </form>
    </div>
  </div>
</nav>

<div *ngIf="themeControllerService.getTheme()!=='theme-blue' && themeControllerService.getTheme()!='theme-default'" class="green-theme">
  <header [ngClass]="{'sticky': isSticky}" class="d-flex justify-content-between align-items-center">
    <div class="logo mr-1">
      <img class="mx-2 green-logo-img" [src]="getAssetPath('pijam-green-logo.svg')" alt="Logo" />
      <span class="brand-name">Codemitra</span>
    </div>

    <div class="user-actions">
      <div *ngIf="!isAuth" class="authenticate-container">
        <button type="button" id="OpenLoginModal" class="btn btn-outline-success mx-3" (click)="onSignInClick()"
          *ngIf="!isAuth" data-toggle="modal" data-target="#exampleModalCenter">Sign in</button>
      </div>

      <span class="user-name-green" *ngIf="isAuth && isLargeScreen">Welcome, <strong>{{ name }}</strong> </span>

      <div class="dropdown" *ngIf="isAuth && !hideAccountButton">
        <button class="btn btn-outline-success dropdown-toggle accounts-btn" type="button" id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Account <img [src]="getAssetPath('green-theme/down-arrow.svg')" />
        </button>

        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
          <a class="dropdown-item dropdown-item-name">{{name}}</a>
          <!-- <a class="dropdown-item" (click)="closeNavbar()" [routerLink]="['/setpin']">Change/Set Pin</a> -->
          <a class="dropdown-item" (click)="closeNavbar()" *ngIf="isAuth" (click)="onLogoutGreenTheme()">Log out</a>
        </div>
      </div>

      <!-- Language selector dropdown -->
      <!-- hard-coding -->
      <div class="dropdown"  *ngIf="competitionId== 5 || competitionId== 20">
        <button class="btn btn-outline-success language-btn dropdown-toggle" type="button" id="languageDropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="mr-1" [src]="getAssetPath('green-theme/language-icon.svg')" />
          <img class="hide-on-small-screen" [src]="getAssetPath('green-theme/down-arrow.svg')" />
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="languageDropdown">
          <a *ngFor="let language of currentLanguages" class="dropdown-item" (click)="selectLanguage(language.code)">
            {{ language.name }}
          </a>
        </div>
      </div>

    </div>
  </header>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true" data-backdrop="static">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <img [src]="getLogoUrl()" alt="Image" width="80px" class="img-fluid logo-image-modal mx-auto">
      <div class="modal-header">
        <br><br><br>
        <div class="text-container">
          <h3 class="text-animation">Code Mitra</h3>
        </div>

        <!-- close modal -->
        <button (click)="onCloseModal()" type="button" id="closeModalButton" class="close" data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>

      </div>

      <div class="modal-body">
        <!-- calling login component -->
        <pijam-modal></pijam-modal>
        <!-- <p class="new-to-CodeMitra">Don't have an account ?<span (click)="signup()" class="sign-up-text"> Sign up now!</span></p> -->

      </div>

    </div>
  </div>
</div>