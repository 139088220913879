<!-- Audio player :: required to play audio  -->
<div class="audio-player-container">
    <audio *ngIf="audioURL" [src]="audioURL" controls></audio>
</div>


<!-- Main View -->
<div class="AudioRecorderContainer">
    <div class="FullFlex"  [ngSwitch]="state">
        <!-- Initial state :: Recording can be start from here -->
        <div class="recording record" *ngSwitchCase="'INITIAL'" (click)="startRecording()">
            <div class="icon">
                <mat-icon>mic</mat-icon>
            </div>
        </div>
        <!-- Recording state :: Recording is in progress -->
        <div class="recording animate record" *ngSwitchCase="'RECORDING'">
            <div class="icon">
                <mat-icon>mic</mat-icon>
            </div>
        </div>
        <!-- Recorded state :: Showing various options -->
        <div class="FullSpan" *ngSwitchCase="'RECORDED'">
            <ng-container *ngTemplateOutlet="buttonContainer" class="FullSpan"></ng-container>
        </div>
        <!-- Playing state :: Recorded audio is being played -->
        <div class="recording animate playback" *ngSwitchCase="'PLAYING'">
            <div class="icon">
                <mat-icon>music_note</mat-icon>
            </div>
        </div>
        <!-- Uploading statte -->
        <div class="FullSpan" *ngSwitchCase="'UPLOADING'">
            <h3>Uploading Audio...</h3>
            <p>Please do not close the app until it's uploaded</p>
        </div>
        <!-- Uploaded state -->
        <div class="FullSpan" *ngSwitchCase="'UPLOADED'">
            <h3>Successfully uploaded!</h3>
            <p>Taking you to the next step...</p>
        </div>
    </div>
    <ng-container *ngIf="state !== 'RECORDED' && state !== 'UPLOADED' && state !== 'UPLOADING'">
        <ng-container *ngTemplateOutlet="buttonContainer"></ng-container> 
    </ng-container> 
</div>

<!-- Contains all possible buttons :: buttons will hide/show depending on state -->
<ng-template #buttonContainer>
    <div class="button-container">
        <pijam-colorful-button
            *ngIf="state === 'INITIAL' || state === 'RECORDED'"
            (click)="startRecording()"
            text="{{ state === 'INITIAL' ? AudioButtonData?.Start_Recording : AudioButtonData?.record_again }}"
            icon="mic"
            color="blue"
        ></pijam-colorful-button>
        <pijam-colorful-button
            *ngIf="state === 'RECORDING'"
            (click)="stopRecording()"
            [text]="AudioButtonData?.Stop_Recording"
            icon="stop_circle_outlined"
            color="red"
            [disabled]="state !== 'RECORDING'"
        ></pijam-colorful-button>
        <pijam-colorful-button
            *ngIf="state === 'RECORDED'"
            (click)="playAudio()"
            [text]="AudioButtonData?.play_audio"
            icon="play_circle_outline"
            color="blue"
        ></pijam-colorful-button>
        <pijam-colorful-button
            *ngIf="state === 'PLAYING'"
            (click)="stopAudio()"
            [text]="AudioButtonData?.stop_audio"
            icon="stop_circle_outlined"
            color="red"
        ></pijam-colorful-button>
        <pijam-colorful-button
            *ngIf="state === 'RECORDED'"
            (click)="submitAudio()"
            [text]="AudioButtonData?.submit_continue"
            icon="done"
            color="green"
        ></pijam-colorful-button>
        <pijam-colorful-button
            *ngIf="state === 'RECORDED'"
            (click)="handleBack($event)"
            [text]="AudioButtonData?.different_method"
            icon="touch_app"
            color="grey"
        ></pijam-colorful-button>
    </div>
</ng-template>