import {
  Component,
  OnInit,
  Input,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { Login } from 'ngx-pijam-api-client';
import { loginWithPin, IdmService } from 'ngx-pijam-api-client';
import { SetUserSession } from 'ngx-pijam-api-client';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvent } from 'src/app/shared/pi.constants';
import { AuthService } from 'src/app/auth/login/service/Auth.service';
// import { ConfigService } from 'ngx-pijam-api-client';
import { OtpDigitModalComponent } from '../otp-digit-modal/otp-digit-modal.component';
import { TopicDataService } from 'src/app/competition/competition-topics/topic-data.service';
import packageJson from '../../../../package.json';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'pijam-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss'],
})
export class LoginModalComponent implements OnInit {
  @Input() LoginType!: string;
  @ViewChildren(OtpDigitModalComponent)
  otpDigitModals!: QueryList<OtpDigitModalComponent>;

  digits: string[] = [];
  LoginErrorOccured!: boolean;
  focusedInputIndex = 0;
  otpCode = new FormControl('', [
    Validators.required,
    Validators.pattern('^[0-9]{4}$'),
  ]);
  PhoneNumber!: any;
  errorOccured: boolean = false;
  error: string = '';

  constructor(
    private authService: AuthService,
    private login: Login,
    private loginWithPin: loginWithPin,
    private setUserSession: SetUserSession,
    private router: Router,
    private dialog: MatDialog,
    private idmService: IdmService,
    private topicDataService: TopicDataService,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService
    // public config: ConfigService,
  ) {
    // this.isAuthenticated = this.authService.isAuthenticated();
  }

  ngOnInit(): void {}

  handleLoginWithOtp() {
    this.login.execute(this.otpCode.value).subscribe(
      (_) => {
        this.postLogin({
          mobileNumber:
            this.idmService.userProfileDetails?.mobile_num ??
            `+91${this.PhoneNumber}`,
          loginType: 'OTP',
        });
      },
      ({ error = { detail: 'Unknown error' } }) =>
        this.errorHandling(error.detail)
    );
  }

  handleLoginWithPin() {
    this.getNumber();
    if (this.PhoneNumber) {
      this.setUserSession.execute(`+91${this.PhoneNumber}`);
      this.loginWithPin
        .execute({
          username: `+91${this.PhoneNumber}`,
          pin: this.otpCode.value,
        })
        .subscribe(
          (_) => {
            this.postLogin({
              mobileNumber: `+91${this.PhoneNumber}`,
              loginType: 'PIN',
            });
          },
          ({ error = { detail: 'Unknown error' } }) =>
            this.errorHandling(error.detail)
        );
    }
  }

  errorHandling(error: string) {
    this.errorOccured = true;
    this.error = error;
    const errorMessageLabel: any = document.getElementById(
      'showLoginErrorMessage'
    );
    this.LoginErrorOccured = true;
    if (errorMessageLabel) {
      errorMessageLabel.textContent = this.getLoginErrorMessage(
        error === 'User Pin has not been set'
          ? 'Please login to set your pin'
          : error
      );
    }
  }

  getLoginErrorMessage(message: string) {
    console.log(message);
    let tempMessage =
      message === 'The requested resource was not found' ||
      message === 'Unknown User'
        ? 'This phone number is not registered Please check your entry or register a new account.'
        : message;
    return tempMessage;
  }

  closeModal() {
    const tempButton: any = document.getElementById('closeModalButton');
    tempButton.click();
  }

  hasFocus(index: number) {
    return this.focusedInputIndex === index;
  }

  onOtpDigitKeyUp(digit: string | null, index: number) {
    let nextIndex: number;
    if (digit) {
      nextIndex = index + 1;
      this.digits[index] = digit;
      this.otpCode.setValue(
        this.digits.reduce(
          (otpCode, digit) => otpCode + (digit ? digit : ' '),
          ''
        )
      );
    } else {
      nextIndex = index - 1;
    }

    if (nextIndex > -1 && nextIndex < 4) {
      this.focusedInputIndex = nextIndex;
    } else {
      this.focusedInputIndex = -1;
    }
  }

  getNumber() {
    let phoneNumberInput = document.getElementById(
      'phoneNumberInput'
    ) as HTMLInputElement;
    this.PhoneNumber = phoneNumberInput.value;
  }

  resetValues(): void {
    this.otpDigitModals.forEach((modal) => modal.resetValues());
  }

  redirectUrl(): void {
    let destination = '/home'; 
  
    const referrer = this.idmService.getReferrer();
    if (referrer && referrer !== '/landing') {
      destination = referrer;
    }
    this.router.navigateByUrl(destination);
  }
  

  private postLogin({
    mobileNumber,
    loginType,
  }: {
    mobileNumber: string;
    loginType: 'OTP' | 'PIN';
  }) {  
    const phoneNumber: string =
      '91' + mobileNumber.slice(mobileNumber.length - 10);
    mixpanel.identify(phoneNumber);
    mixpanel.register({
      app_type: 'web',
      user_type: this.idmService.userProfileDetails?.user_type,
      phone_number: phoneNumber,
      user_name: this.idmService.get('first_name'),
      app_version: packageJson.version,
    });
    mixpanelEvent.checkpoint('USER_LOGGED_IN', {
      login_type: loginType,
    });
    this.closeModal();
    this.authService.login();
    this.redirectUrl();
    // DEPRECATED :: page-reload at the end
    // if (shouldReload) {
    //   window.location.reload();
    // }
  }
}
