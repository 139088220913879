import { Component, OnInit ,ViewChild } from '@angular/core';
import { RequestOtp } from 'ngx-pijam-api-client';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { DataService } from 'src/app/services/data.service';
import { LoginModalComponent } from '../login-modal/login-modal.component';
@Component({
  selector: 'pijam-request-otp-modal',
  templateUrl: './request-otp-modal.component.html',
  styleUrls: ['./request-otp-modal.component.scss']
})
export class RequestOtpModalComponent implements OnInit {
  showLogin:boolean=true;
  isRequestingOtp = false;  
  username = new FormControl('', [Validators.required, Validators.pattern('^[0-9]{10}$')]);
  timeoutId!:any;
  resendTimer!: number ;
  changeNumber:boolean=false;
  errorOccured:boolean=false;
  error:string='';
  isPhoneNumberDisabled:boolean=false;
  LoginType:string='pin'
  @ViewChild(LoginModalComponent) loginModal!: LoginModalComponent;
  constructor(private dataService: DataService,private requestOtp: RequestOtp, private router: Router, private dialog: MatDialog) { }

  ngOnInit(): void {
    
    this.showLogin=true
    // this.dataService.currentBoolean.subscribe(booleanValue => this.showLogin = booleanValue);
  }

  getErrorMessage() {
    let message = '';
    if (this.username.hasError('required')) {
      message = '';
    } else if (this.username.hasError('pattern')) {
      message = 'The number should be 10 digits (without the prefix +91)';
    }

    return message;
  }

    onRequestOtp() {
      const errorMessageLabel:any = document.getElementById('showLoginErrorMessage');
      errorMessageLabel.textContent=''
      this.errorOccured=false;
      this.showLogin=false
      this.error=''
      if (this.timeoutId) {
        clearTimeout(this.timeoutId);
      }
      if (this.username.valid) {
        this.isPhoneNumberDisabled=true;
        this.changeNumber = true;
        this.requestOtp.execute(`+91${this.username.value}`).subscribe(
          _ => {
            this.showLogin = true;
            this.onRequestOtpDisable();
          },
          (errorResponse ) => {
            this.errorOccured = true;
            const errorCode = errorResponse?.status || null;
            const errorMessage = errorResponse?.error || 'Unknown error';
            if (errorMessageLabel) {
              errorMessageLabel.textContent = this.getLoginErrorMessage(errorCode, errorMessage)
            }
          }
        );
      }
    }
  
  
  onRequestOtpDisable() {
    this.resendTimer = 30;
    this.isRequestingOtp = true;
    this.username.disable(); 
    
  
    // Enable the button after 30 seconds
    this.timeoutId = setTimeout(() => {
      this.isRequestingOtp = false;
      // this.username.enable();
    }, 30000);
  
    // Start the countdown timer
    let countdownInterval = setInterval(() => {
      this.resendTimer--;
      if (this.resendTimer <= 0 || this.isRequestingOtp===false) {
        clearInterval(countdownInterval);
       
        this.isRequestingOtp = false;
      this.username.enable();
   
      this.resendTimer = 30;
      }
    }, 1000);
  }

  ChangeNumber(){

    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
    this.isPhoneNumberDisabled=false;
    this.closeErrorMessage()
    this.errorOccured=false; 
    this.changeNumber=false;
    this.username.reset();
    this.username.enable();
    this.resendTimer = 30;
    this.isRequestingOtp = false; 
    this.showLogin=false;
  }

  resetOncloseModal(){
    this.showLogin=true;
    this.closeErrorMessage()
    this.isPhoneNumberDisabled=false;
    this.isRequestingOtp = false; 
    this.changeNumber=false;
    this.username.reset();
    this.username.enable();
    this.errorOccured=false;
    this.LoginType='pin'
    this.loginModal.resetValues();
    
  }
  getLoginErrorMessage(errorCode: number, message: string): string {
    console.log("Error code:", errorCode, "Message:", message);

    switch (errorCode) {
        case 404:
            return "Sorry! We couldn't find an account with that mobile number. You might want to try again with a different number or set up a new account.";

        default:
            return "OTP service is unavailable right now. Please try again later.";
    }
}

signup(){
  this.closeErrorMessage()
  this.closeModal()
  this.signupNavigation()
  this.errorOccured=false; 
  this.isPhoneNumberDisabled=false;
}
signupNavigation() {
  // const href = window.location.href;

  // if (href.includes('Eco-Life')) {
  //   this.router.navigate(['/auth/registration']);
  // } else {
    this.router.navigate(['/auth']);
    // }
}


closeModal(){
  this.showLogin=true;
  console.log("fetch value")
  this.errorOccured=false; 
  const tempButton:any = document.getElementById('closeModalButton');
  tempButton.click();
  
}
closeErrorMessage(){
  const errorMessageLabel:any = document.getElementById('showLoginErrorMessage');
  if (errorMessageLabel) {
    errorMessageLabel.textContent = ""
  }
}

ToggleLoginType(){
  const errorMessageLabel:any = document.getElementById('showLoginErrorMessage');
  
  if (errorMessageLabel) {
    errorMessageLabel.textContent = ''
  }
  this.showLogin=false;
  if(this.LoginType==='otp'){
    this.LoginType='pin'
    this.showLogin=true;
    this.username.enable();
  }else if (this.LoginType==='pin'){
    this.isRequestingOtp = false; 
      this.LoginType='otp'
      

    }
}

}
