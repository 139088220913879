import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private booleanSource = new BehaviorSubject<boolean>(false);
  private referrerPath: string | null = null;
  currentBoolean = this.booleanSource.asObservable();

  changeBoolean(booleanValue: boolean) {
    this.booleanSource.next(booleanValue)
  }

  setReferrer(path: string | null) {
    this.referrerPath = path;
  }
  
  getReferrer(): string | null {
    return this.referrerPath;
  }
  
}
