import { Component, ElementRef, ViewChild, AfterViewChecked, Renderer2, Output, EventEmitter, Input } from '@angular/core';
import { S3Service } from 'src/app/services/s3.service';
import { mixpanelEvent } from '../pi.constants';
import buttonData from '../../competition/competition-topics/competition-topic/competition-multimedia-input/multimedia.json'
import { LanguageService } from 'src/app/services/language.service';
import { Subscription } from 'rxjs';

// Declare MediaRecorder and BlobEvent globally
declare var MediaRecorder: any;

@Component({
  selector: 'app-video-recorder',
  templateUrl: './video-recorder.component.html',
  styleUrls: ['./video-recorder.component.scss']
})
export class VideoRecorderComponent implements AfterViewChecked {
  @ViewChild('video') videoElement!: ElementRef;
  @ViewChild('playback') playbackElement!: ElementRef;
  @Output() uploadUrlEmitter: EventEmitter<{ idea_video: string }> = new EventEmitter<{ idea_video: string }>();
  @Input() fileName: string = `video-${Math.random()*9999}-${Math.random()*9999}-${Math.random()*9999}.webm`
  @Input() showBackButton = false;
  @Output() onBackClicked: EventEmitter<void> = new EventEmitter<void>();
  state: 'initial' | 'permission-granted' | 'error' | 'recording' | 'recorded' | 'uploading' | 'uploaded' = 'initial';
  error: string | null = null;
  videoMimeType:  string = "video/webm; codecs=vp9";
  @Input() rendererContextData: { [key: string]: string } = {};
  videoButtonData!:any ;
  languageCode!: string;
  private mediaRecorder: any;
  private recordedChunks: any[] = [];
  private stream: MediaStream | null = null;
  showPlayButton: boolean = true;
  uploadUrl: string = "";

  private languageChangeSubscription!: Subscription;


  constructor(private renderer: Renderer2, private s3Service: S3Service,private languageService: LanguageService,) {}
  
  ngOnInit(): void {
    this.setLanguage();

   
  }
  setLanguage(): void {
    this.languageCode = this.languageService.getCurrentLanguage();
    this.updateData();
  
    this.languageChangeSubscription = this.languageService.subscribeToLanguageChanges().subscribe(language => {
      this.languageCode = language;
      this.updateData();
    });
  }
  updateData(): void {
    this.videoButtonData = (buttonData as { [key: string]: any })[this.languageCode].RECORD_VIDEO_LABELS;
 }

  ngAfterViewChecked(): void {
    // The elements will be available after this point due to dynamic ViewChild
    if (this.playbackElement) {
      this.renderer.listen(this.playbackElement.nativeElement, 'play', () => {
        this.showPlayButton = false;
      });

      this.renderer.listen(this.playbackElement.nativeElement, 'pause', () => {
        this.showPlayButton = true;
      });

      this.renderer.listen(this.playbackElement.nativeElement, 'ended', () => {
        this.showPlayButton = true;
      });
    }
  }

  requestPermissions() {
    if (!this.videoElement) {
      this.error = 'Video element is not available';
      return;
    }

    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(stream => {
        this.stream = stream;
        this.state = "permission-granted";
        if (this.videoElement.nativeElement) {
          this.videoElement.nativeElement.srcObject = stream;
          this.videoElement.nativeElement.muted = true; // Ensure video element is muted
        }
        this.state = 'recording';
        this.startRecording();
      })
      .catch(err => {
        this.error = 'Could not get permissions: ' + err.message;
        this.state = 'error';
        this.stopRecording();
        mixpanelEvent.checkpoint("ERROR_ENCOUNTERED", {
          type: "VIDEO",
          message: err.message ?? "",
          when: "getting-user-media",
          ...this.rendererContextData,
        })
      });
  }

  startRecording = () => {
    mixpanelEvent.buttonClicked("START_RECORDING", {
      type: "VIDEO",
      ...this.rendererContextData,
    })
    if (this.stream) {
      this.recordedChunks = [];

      // options = { mimeType: 'video/webm; codecs=vp9' };
      this.videoMimeType = 'video/webm; codecs=vp9'
      if (!MediaRecorder.isTypeSupported(this.videoMimeType)) {
        this.videoMimeType = 'video/webm; codecs=vp8';
        if (!MediaRecorder.isTypeSupported(this.videoMimeType)) {
          this.videoMimeType = 'video/webm';
          if (!MediaRecorder.isTypeSupported(this.videoMimeType)) {
            this.videoMimeType = '';
          }
        }
      }

      try {
        this.mediaRecorder = new MediaRecorder(this.stream, { mimeType: this.videoMimeType });
      } catch (e: any) {
        mixpanelEvent.checkpoint("ERROR_ENCOUNTERED", {
          type: "VIDEO",
          message: e.message ?? "",
          when: "starting-video-recording-media-recorder-init",
          ...this.rendererContextData,
        })
        return;
      }

      this.mediaRecorder.ondataavailable = (event: any) => {
        if (event.data.size > 0) {
          this.recordedChunks.push(event.data);
        }
      };
      
      this.mediaRecorder.onstop = () => {
        const blob = new Blob(this.recordedChunks, { type: this.videoMimeType });
        const url = URL.createObjectURL(blob);
        this.playbackElement.nativeElement.src = url;

        // Additional cleanup
        if (this.videoElement && this.videoElement.nativeElement) {
          for (const track of this.videoElement.nativeElement.srcObject.getTracks()) {
            track.stop();
          }
          this.videoElement.nativeElement.srcObject = null; // Clear the video element source
        }
        
        if (this.stream) {
          this.stream.getTracks().forEach(track => track.stop());
          this.stream = null; // Clear the stream after stopping
        }
        
        this.state = 'recorded';
        this.mediaRecorder = null;
      };

      this.mediaRecorder.start();
    } else {
      mixpanelEvent.checkpoint("ERROR_ENCOUNTERED", {
        type: "VIDEO",
        message: "No stream available",
        when: "start-video-recording",
        ...this.rendererContextData,
      })
      this.error = 'No stream available';
    }
  }

  stopRecording = () => {
    mixpanelEvent.buttonClicked("STOP_RECORDING", {
      type: "VIDEO",
      ...this.rendererContextData,
    })

    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      this.mediaRecorder.stop();
    }

    // Reset the video element source to null
    if (this.videoElement && this.videoElement.nativeElement) {
      for (const track of this.videoElement.nativeElement.srcObject.getTracks()) {
        track.stop();
      }
      this.videoElement.nativeElement.srcObject = null;
    }

    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null; // Clear the stream after stopping
    }

    // Reset the media recorder and state
    this.mediaRecorder = null;
    this.state = 'recorded';
  }

  playVideo(): void {
    mixpanelEvent.buttonClicked("PLAY_PREVIEW", {
      type: "VIDEO",
      playback_element_present: this.playbackElement ? "yes" : "no",
      ...this.rendererContextData,
    })
    if (this.playbackElement) {
      this.playbackElement.nativeElement.play();
    }
  }

  uploadVideo = async () =>  {
    this.state = "uploading";
    mixpanelEvent.buttonClicked("SUBMIT_AND_CONTINUE", {
      type: "VIDEO",
      ...this.rendererContextData
    })
    try {

      const fileName: string = this.fileName + `${Math.random() * 9999 }` + ".webm"; 
      const blob = new Blob(this.recordedChunks, { type: this.videoMimeType });
      const file = new File([blob], fileName, { type: this.videoMimeType });
      
      const bucketName: string = 'user-responses-storage'; 
      // const fileName: string = file.name + ".webm";
      // const acl: string = 'private'; (use it if you want to make the file private.)

      await this.s3Service.uploadFile(file, bucketName, fileName);
      this.uploadUrl = `https://${bucketName}.s3.amazonaws.com/${fileName}`;
      this.state = "uploaded";
      this.continueAfterUpload()
    } catch (error: any) {
      this.error = 'Upload failed: ' + error.message;
      this.state = 'error';
      mixpanelEvent.checkpoint("ERROR_ENCOUNTERED", {
        type: "VIDEO",
        message: error.message ?? "",
        ...this.rendererContextData
      })
    }
  }

  continueAfterUpload = async () => {
     this.uploadUrlEmitter.emit({
        idea_video: this.uploadUrl
     });
  }

  handleBack = (event: any) => {
    event?.stopPropagation();
    this.onBackClicked.emit();
  }

  ngOnDestroy(): void {
    if (this.languageChangeSubscription) {
      this.languageChangeSubscription.unsubscribe();
    }
  }
}
