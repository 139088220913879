<pijam-toolbar (toggleSidebar)="onToggleSidebar()"></pijam-toolbar>
<mat-sidenav-container>
  <mat-sidenav [opened]="sidebarOpened">
    <mat-list>
      <mat-list-item>
        <button mat-button [routerLink]="['/']">Home</button>
      </mat-list-item>
      <mat-list-item>
        <button mat-button [routerLink]="['/badges']">Badges earned</button>
      </mat-list-item>
      <mat-list-item *ngIf="userType !== 'teacher'">
        <button mat-button [routerLink]="['/lms']">CS course for Teachers</button>
      </mat-list-item>
      <mat-list-item>
        <button mat-button [routerLink]="['/virtual-lab']">Open Lab</button>
      </mat-list-item>
      <mat-list-item>
        <button mat-button (click)="onLogout()">Logout</button>
      </mat-list-item>
    </mat-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
</mat-sidenav-container>
