import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
@Component({
  selector: 'pijam-meta-tags',
  templateUrl: './meta-tags.component.html',
  styleUrls: ['./meta-tags.component.scss']
})
export class MetaTagsComponent implements OnInit {

  constructor(private meta: Meta) {}

  ngOnInit(): void {
    this.meta.addTags([ 
  { name: 'title', content: 'codemitra.org | code mitra | codemitra' },
  { name: 'description', content: 'Discover a comprehensive e-learning platform designed for students. Access interactive courses, educational resources' },
  { name: 'keywords', content: 'pijam,e-learning, online education, courses, interactive learning, educational resources, student resources' },
  { name: 'author', content: 'Pi Jam Foundation' },
  { name: 'viewport', content: 'width=device-width, initial-scale=1.0' },
  { property: 'og:title', content: 'E-Learning for Students | https://codemitra.org' },
  { property: 'og:description', content: 'Discover a comprehensive e-learning platform designed for students. Access interactive courses, educational resources' },
  { property: 'og:url', content: 'https://www.thepijam.org/' }
  ]);
  }
}
