import { Component, OnInit } from '@angular/core';
import { Logout, IdmService } from 'ngx-pijam-api-client';
import { Router } from '@angular/router';
import mixpanel from 'mixpanel-browser';
import { mixpanelEvent } from './pi.constants';

@Component({
  selector: 'pijam-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
})
export class SidebarLayoutComponent implements OnInit {
  sidebarOpened = false;
  userType!: string | number | null;

  constructor(
    private logout: Logout,
    private router: Router,
    private idmService: IdmService
  ) {}

  ngOnInit(): void {
    this.userType = this.idmService.get('user_type');
  }

  onToggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
  }

  async onLogout() {
    await this.logout.execute();
    mixpanelEvent.checkpoint('USER_LOGGED_OUT');
    mixpanel.reset();
    this.router.navigate(['/landing']);
  }
}
