

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import  {Topic} from '../competition.interface';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class TopicDataService {
  private triggerActivityInstruction = new Subject<void>();
  triggerActivityInstruction$ = this.triggerActivityInstruction.asObservable();

  private currentTopicIndexSubject = new BehaviorSubject<number>(0);
  currentTopicIndex$ = this.currentTopicIndexSubject.asObservable();

  private currentTopicDataSubject = new BehaviorSubject<Topic | null>(null);
  currentTopicData$ = this.currentTopicDataSubject.asObservable();

  private resumeButtonStateSubject = new BehaviorSubject<number | null>(null);
  resumeButtonState$ = this.resumeButtonStateSubject.asObservable();

  private isCompleteSubject = new BehaviorSubject<boolean>(false);
  isComplete$ = this.isCompleteSubject.asObservable();

  private competitionId: number | undefined;
  
  public queryParams: any = {};
  setCurrentTopicIndex(index: number) {
    this.currentTopicIndexSubject.next(index);
  }

  getCurrentTopicIndex(): number {
    return this.currentTopicIndexSubject.value;
  }

  setCurrentTopicData(data: Topic | null) {
    this.currentTopicDataSubject.next(data);
  }

  getCurrentTopicData(): Topic | null {
    return this.currentTopicDataSubject.value;
  }
  initializeResumeButtonState(): void {
    this.resumeButtonStateSubject.next(null);
  }

  updateResumeButtonState(value: number): void {
    this.resumeButtonStateSubject.next(value);}
  getTopicType(): string | null {
    const currentTopicData = this.currentTopicDataSubject.value;
    return currentTopicData ? currentTopicData.topic_type : null;
  }

  // getReferralCode(): string | undefined {
  //   return this.referralCode;
  // }

  // setReferralCode(code: string): void {
  //   this.referralCode = code;
  // }

  getQueryParam(key: string): any {
    return this.queryParams[key];
  }

  setQueryParam(key: string, value: any): void {
    this.queryParams[key] = value;
  }
  setCompetitionId(id: number): void {
    this.competitionId = id;
  }

  getCompetitionId(): number | undefined {
    return this.competitionId;
  }

  triggerActivityInstructionfn() {
    this.triggerActivityInstruction.next();
  }
  resetState(): void {
    this.currentTopicIndexSubject.next(0);
    this.currentTopicDataSubject.next(null);
    this.resumeButtonStateSubject.next(null);
  }

  setIsComplete(status: boolean): void {
    this.isCompleteSubject.next(status);
  }

  getIsComplete(): boolean {
    return this.isCompleteSubject.value;
  }
}
