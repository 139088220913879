import { Component, Inject, Input } from '@angular/core';
import mixpanel from 'mixpanel-browser';
import {
  IdmService,
  PointsService,
  CoursesService,
  BadgesService,
} from 'ngx-pijam-api-client';
import { environment } from 'src/environments/environment';
import packageJson from '../../package.json';
import { SamikshaBackButtonService } from './services/samiksha-back-button.service';
import { ToastrService } from 'ngx-toastr';
import { ThemeControllerService } from './services/theme-controller.service';

@Component({
  selector: 'pijam-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'pijam-web-app';
  // isAuth!: boolean;
  @Input() courseId!: number;
  isOnline: boolean = navigator.onLine;

  constructor(
    @Inject('Window') public window: any,
    private idmService: IdmService,
    private coursesService: CoursesService,
    private pointsService: PointsService,
    private badgesService: BadgesService,
    private backButtonService: SamikshaBackButtonService,
    private toastr: ToastrService,
    private themeService: ThemeControllerService

  ) {
    this.init();
    window.handleBack = this.handleBack.bind(this);
  }
  ngOnInit(): void {
    window.addEventListener('online', this.updateOnlineStatus.bind(this));
    window.addEventListener('offline', this.updateOnlineStatus.bind(this));
    this.updateOnlineStatus(); 
  }

  updateOnlineStatus() {
    this.isOnline = navigator.onLine;
  
    if (!this.isOnline) {
      this.showOfflineMessage();
    }
  }
  
  async init() {
    const loggedIn = await this.idmService.isLoggedInUser();
    this.badgesService.resetSessionProgress();
    mixpanel.init(environment.mixpanelProjectToken, {
      debug: true,
      // ignore_dnt: true,
      persistence: 'localStorage',
      ignore_dnt: true,
    });
    if (loggedIn) {
      this.coursesService.refreshActiveCourses();
      this.coursesService.refreshActiveCoursesEnrollments();
      this.pointsService.refreshPoints();
      this.mixpanelIdentifyUser();
    } else {
      mixpanel.reset();
      mixpanel.register({
        app_type: 'web',
        app_version: packageJson.version ?? '',
      });
    }
  }
  checkURLContainsValue(url: string, value: string): boolean {
    return url.includes(value);
  }
  mixpanelIdentifyUser() {
    const phoneNumberRaw: string = (
      (this.idmService.get('mobile_num') || '') as string
    ).trim();
    const phoneNumber: string =
      '91' + phoneNumberRaw.slice(phoneNumberRaw.length - 10);
    mixpanel.identify(phoneNumber);
    console.log('Identification done: :', phoneNumber);
    mixpanel.register({
      app_type: 'web',
      user_type: this.idmService.get('user_type'),
      phone_number: phoneNumber,
      user_name: this.idmService.get('first_name'),
      app_version: packageJson.version,
    });
    // if (phoneNumber !== undefined || phoneNumber !== null) {
    //   mixpanel.identify(phoneNumber);

    //   mixpanel.people.set({
    //     $user_type: this.idmService.userProfileDetails,
    //     $first_name: this.idmService.get('first_name'),
    //   });
    // }
  }

handleBack(): boolean {
    return this.backButtonService.handleBack();
  }

  showOfflineMessage() {
    this.toastr.info(
      `No Internet Connection. Please check your internet connection and try again.`,
      'Offline',
      {
        timeOut: 5000,
        progressBar: true,
        closeButton: true,
        positionClass: 'toast-top-right',
      }
    );
  }

  // *ngIf="checkURLContainsValue(window.location.href, 'courses/{courseId}/ ')"
}
