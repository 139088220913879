<div class="form-group">
  <label for="username">Mobile number
    <!-- <small class="text-danger" *ngIf="username.invalid">{{ getErrorMessage() }}</small> -->
  </label>
  <div class="input-group">
    <div class="input-group-prepend">
      <span class="input-group-text">+91</span>
    </div>
    <input class="NumberInput" id="phoneNumberInput" class="form-control" type="text"
      placeholder="Enter 10 Digit Number" [disabled]="isPhoneNumberDisabled" [formControl]="username" maxlength="10"
      pattern="[0-9]{10}" />
    <div class="input-group-append">
      <button *ngIf="this.LoginType==='otp'" actions [disabled]="isRequestingOtp || !(username.valid)"
        class="btn btn-outline-primary custom-btn" (click)="onRequestOtp()">Request OTP</button>
    </div>
  </div>

  <!-- <span *ngIf="errorOccured"><p class="new-to-CodeMitra">Dont have an account ?<span (click)="signup()" class="sign-up-text"> Sign up now!</span></p></span> -->
  <!-- <div *ngIf="!errorOccured" class="resend-otp-container"> -->
    <div>
      <label *ngIf="isRequestingOtp && LoginType==='otp'" class="resend-otp">Resend OTP in <span
          class="timer">{{resendTimer}}</span> Sec</label>
    <!-- </div> -->
  
  <label *ngIf="changeNumber" (click)="ChangeNumber()" class="change-num">Change Number?</label>
</div>
  <div id="showLoginErrorMessage"></div>
  <button id="onCloseModalReqopt" hidden (click)="resetOncloseModal()"></button>


  <div *ngIf="showLogin" class="pijam-login-container">
    <pijam-login-modal [LoginType]="LoginType" (close)="closeModal()"></pijam-login-modal>
  </div>
  <div class="loginTypeText" (click)="ToggleLoginType()">Login with <span
    class="loginType">{{LoginType==='otp'?'PIN':'OTP'}}</span>?</div>
  <p class="new-to-CodeMitra">{{error==='Unknown User'||error==='The requested resource was not found'?'Dont have an account?':'New to Code Mitra ?'}}<span (click)="signup()" class="sign-up-text"> Sign up</span></p>

</div>