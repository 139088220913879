import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NonAuthGuard } from './guards/non-auth.guard';

const routes: Routes = [
  {
    path: 'inappauthentication',
    loadChildren: () => import('./auth/token-authenticator/token-authentication.module').then(m => m.TokenAuthenticationModule)
  },
  {
    path: 'auth',
    canActivate: [NonAuthGuard],
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'home',
    canActivate: [AuthGuard],
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'courses',
    canActivate: [AuthGuard],
    loadChildren: () => import('./courses/courses.module').then(m => m.CoursesModule),
  },
  {
    path: 'virtual-lab',
    canActivate: [AuthGuard],
    loadChildren: () => import('./virtual-lab/virtual-lab.module').then(m => m.VirtualLabModule)
  },
  {
    path: 'lms',
    canActivate: [AuthGuard],
    loadChildren: () => import('./lms/lms.module').then(m => m.LmsModule)
  },
  {
    path: 'badges',
    canActivate: [AuthGuard],
    loadChildren: () => import('./badges/badges.module').then(m => m.BadgesModule)
  },
  {
    path: 'setpin',
    canActivate: [AuthGuard],
    loadChildren: () => import('./set-pin/set-pin.module').then(m => m.SetPinModule)
  },
  {
    path: 'landing',
    canActivate: [NonAuthGuard],
    loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule)
  },
  {
    path: 'hour-of-code',
    canActivate: [AuthGuard],
    loadChildren: () => import('./courses/hour-of-code/hour-of-code.module').then(m => m.HourOfCodeModule)
  },
  {
    path: 'delete-account',
  canActivate: [NonAuthGuard],
  loadChildren: () => import('./delete-account/delete-account.module').then(m => m.DeleteAccountModule)
  },
  {
    path: 'competition',
    loadChildren: () => import('./competition/competition.module').then(m => m.CompetitionModule)
  },
  {
    path: '',
    redirectTo: '/landing',
    pathMatch: 'full'
  },
  {
    path: 'client/:client',
    canActivate: [AuthGuard],
    loadChildren: () => import('./partner-landing/partner-landing.module').then(m => m.PartnerLandingModule)
  },
  {
    path: 'eco-life',
    loadChildren: () => import('./url-shortener/url-shortener.module').then(m => m.UrlShortenerModule)
  },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }


