import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { RequestOtpModalComponent } from './request-otp-modal/request-otp-modal.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { OtpDigitModalComponent } from './otp-digit-modal/otp-digit-modal.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { AuthLayoutModalComponent } from './auth-layout-modal/auth-layout-modal.component';

@NgModule({
  declarations: [
    ModalComponent,
    RequestOtpModalComponent,
    OtpDigitModalComponent,
    LoginModalComponent,
    AuthLayoutModalComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatListModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,
  
  ],
  exports: [
    ModalComponent
  ]
})
export class ModalModule { }
