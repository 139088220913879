import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MetaTagsComponent } from './meta-tags.component';
@NgModule({
  declarations: [
    MetaTagsComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    MetaTagsComponent
  ]
})
export class MetaTagsModule { }
