import { Component, OnInit,Input  } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDismissReasons, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'pijam-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  
})
export class ModalComponent implements OnInit {
  closeResult!: string;
  constructor(private modalService: NgbModal) {}

  ngOnInit(): void {
  }
	openVerticallyCentered(content:any) {
		this.modalService.open(content, { centered: true });
	}
}
