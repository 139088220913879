import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CourseTileData } from 'ngx-pijam-api-client';
import { getAssetPath } from 'src/Utility/path-util';
@Component({
  selector: 'pijam-course-tile',
  templateUrl: './course-tile.component.html',
  styleUrls: ['./course-tile.component.scss']
})
export class CourseTileComponent implements OnInit {

  @Output() onSelect: EventEmitter<CourseTileData> = new EventEmitter()
  @Input() course!: CourseTileData;
  getAssetPath=getAssetPath
  
  constructor() { }

  ngOnInit(): void {
  }

  onClick() {
    this.onSelect.next(this.course)
  }

}
