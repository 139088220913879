import { Component, OnInit } from '@angular/core';
import { HourOfCodeService } from 'ngx-pijam-api-client';
import { CourseTileData } from 'ngx-pijam-api-client';
import { Router } from '@angular/router';

@Component({
  selector: 'pijam-hour-of-code',
  templateUrl: './hour-of-code.component.html',
  styleUrls: ['./hour-of-code.component.scss'],
})
export class HourOfCodeComponent implements OnInit {
  hocExercises: Array<CourseTileData> = [];

  constructor(private hocService: HourOfCodeService, private router: Router) {}

  ngOnInit(): void {
    this.hocService.fetchCourses().subscribe((res) => {
      this.hocExercises = res.map(
        (hocExercise) =>
          ({
            id: hocExercise.hoc_id,
            name: hocExercise.hoc_name,
            image: hocExercise.hoc_image,
            status: hocExercise.status,
            short_desc: hocExercise.short_desc,
          } as CourseTileData)
      );
    });
  }

  loadHoc(hocExerciseData: CourseTileData) {
    this.router.navigate([`/hour-of-code/${hocExerciseData.id}`]);
  }
}
