import { Input ,OnInit,Component, ElementRef, ViewChild,EventEmitter, Output ,ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'pijam-pin-input',
  templateUrl: './pin-input.component.html',
  styleUrls: ['./pin-input.component.scss']
})
export class PinInputComponent implements OnInit {
  @ViewChild('input0') input0!: ElementRef<HTMLInputElement>;
  @ViewChild('input1') input1!: ElementRef<HTMLInputElement>;
  @ViewChild('input2') input2!: ElementRef<HTMLInputElement>;
  @ViewChild('input3') input3!: ElementRef<HTMLInputElement>;
  @Input() text!:string;
  @Output() inputPinRefs = new EventEmitter<string>();
  @Output() confirmPinRefs = new EventEmitter<string>();
  @Output() enterPinRefs = new EventEmitter<string>();
  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
  }

  onInput(index: number) {
    const inputFields = [
      this.input0,
      this.input1,
      this.input2,
      this.input3
    ];
  
    const value = inputFields[index].nativeElement.value;
  const pin = inputFields.map(input => input.nativeElement.value).join('');
  this.inputPinRefs.emit(pin);
  this.enterPinRefs.emit(pin);
  this.confirmPinRefs.emit(pin);
  
    if (value) {
      if (index < inputFields.length - 1) {
        inputFields[index + 1].nativeElement.focus();
      } else {
        const otp = inputFields.map(input => input.nativeElement.value).join('');
        this.inputPinRefs.emit(otp);
        this.enterPinRefs.emit(pin);
        this.confirmPinRefs.emit(pin);
      }
    }
    this.cdr.detectChanges();
  }

  onBackspace(index: number) {
    this.inputPinRefs.emit("");
    this.enterPinRefs.emit("");
    this.confirmPinRefs.emit("");
    const inputFields = [
      this.input0,
      this.input1,
      this.input2,
      this.input3
    ];

    const value = inputFields[index].nativeElement.value;

    if (!value && index > 0) {
      inputFields[index - 1].nativeElement.focus();
    }
  }
}
